/*

    TODO:
    - colorpicker & hex validation

*/
import { provide, Ref, ref, computed, ComputedRef, inject, watch, onMounted } from 'vue'
import { Reports, Targets } from '@opteo/types'
import { useDomain } from '@/composition/domain/useDomain'
import { useUser } from '@/composition/user/useUser'
import format from 'date-fns/format'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import parseISO from 'date-fns/parseISO'
import round from 'lodash-es/round'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'

import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { useTeam, TeamMember } from '@/composition/user/useTeam'
import { delay } from '@/lib/globalUtils'
import { useReportPreferences } from '@/composition/reports/useReportPreferences'
import { useReportsList } from '@/composition/reports/useReportsList'
import { Routes } from '@/router/routes'
import { useRoute, useRouter } from 'vue-router'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useAccount } from '../account/useAccount'

interface ConversionTableHeader {
    key: string
    text: string
}

interface CreateReportExports {
    loading: Ref<boolean>
    title: Ref<string>
    templateId: Ref<number | undefined>
    selectedTemplateId: Ref<number | undefined>
    newReportInfo: Ref<Reports.Core.NewReportInfo | Reports.Core.ReportWithUserDetails | undefined>
    fromDate: Ref<Date>
    toDate: Ref<Date>
    setNewDateRange: (dates: { start: Date; end: Date }) => void
    team: Ref<TeamMember[] | undefined>
    updateSenderFields: (fields: Partial<Reports.Core.NewReportInfo['sender']>) => void
    updateRecipientFields: (fields: Partial<Reports.Core.NewReportInfo['recipient']>) => void
    createReport: () => Promise<void>
    validateTitle: (title: string) => string | undefined
    creationFormReady: ComputedRef<boolean>
    creatingReport: Ref<boolean>
    creatingReportError: Ref<boolean>
    conversionTableHeaders: ComputedRef<ConversionTableHeader[]>
    conversionTableItems: ComputedRef<Record<string, string | boolean | number>[]>
    selectedConversionTypeCount: Ref<number>
    allConversionsSelected: ComputedRef<boolean>
    selectConversionType: (name: string, selected: boolean) => void
    validateSpendBottom: (val: number) => string | undefined
    validateSpendTop: (val: number) => string | undefined
    validateCpaBottom: (val: number) => string | undefined
    validateCpaTop: (val: number) => string | undefined
    validateRoasBottom: (val: number) => string | undefined
    validateRoasTop: (val: number) => string | undefined
    saveToLocalStorage: () => void
    addSchedule: (schedule: { interval: string; dayOfMonth: number }) => void
    removeSchedule: (scheduleId: number | undefined) => void
    scheduleAlreadyCreated: ComputedRef<boolean>
    isUsingCpa: ComputedRef<boolean>
}

const MIN_REPORT_CREATE_TIME = 4000
const MAX_TITLE_CHARS = 25

export function isExistingReport(
    report: Reports.Core.NewReportInfo | Reports.Core.ReportWithUserDetails
): report is Reports.Core.ReportWithUserDetails {
    return (report as Reports.Core.ReportWithUserDetails).title !== undefined
}

/*
    Here's how the data flow works.
    - `uneditedNewReportInfo` is returned from useAPI, and refreshed whenever the user changes the date range.
    - `newReportInfo` is set to uneditedNewReportInfo the first time it loads,
        and from then ignores new versions of uneditedNewReportInfo (except for conversion types)
    - All form variables (eg title, target fields) live in `newReportInfo`
    - When creating or editing a report, newReportInfo is the main argument, 
        since the backend's update/createReport expects almost the same data object 
        as getReport/GetNewReportInfo returns. Convenient!
*/

export function provideCreateReport(mode: 'create' | 'update') {
    /*
        Setup
    */
    const { team } = useTeam()
    const { groupId } = useUser()
    const { domainId, domainInfo, currencySymbol, performanceMode } = useDomain()
    const { accountId } = useAccount()
    const { preferences } = useReportPreferences()
    const { mutate: refreshReportsList } = useReportsList()
    const intercom = useIntercom()

    const lastMonth = sub(new Date(), { months: 1 })
    const fromDate = ref(startOfMonth(lastMonth))
    const toDate = ref(endOfMonth(lastMonth))
    const title = ref('Google Ads Report')

    const { currentRoute, push } = useRouter()

    const currentReportId = computed(() =>
        currentRoute.value.params.reportId ? +currentRoute.value.params.reportId : undefined
    )

    const newReportInfo = ref<Reports.Core.NewReportInfo | Reports.Core.ReportWithUserDetails>()

    // If it's crazy but it works, it's not crazy, right??
    const { data: uneditedNewReportInfo, mutate: refreshNewReportInfo } =
        mode === 'create'
            ? useAPI<Reports.Core.NewReportInfo>(Endpoint.GetNewReportInfo, {
                  body: () => {
                      return {
                          group_id: groupId.value,
                          domain_id: domainId.value,
                          from_date: format(fromDate.value, 'yyyy-MM-dd'),
                          to_date: format(toDate.value, 'yyyy-MM-dd'),
                      }
                  },
                  uniqueId: () => `${domainId.value}:${performanceMode.value}`,
                  waitFor: () => domainId.value,
              })
            : useAPI<Reports.Core.ReportWithUserDetails>(Endpoint.GetReport, {
                  body: () => {
                      return {
                          report_id: currentReportId.value,
                          domain_id: domainId.value,
                          options: {
                              from_date: format(fromDate.value, 'yyyy-MM-dd'),
                              to_date: format(toDate.value, 'yyyy-MM-dd'),
                          },
                      }
                  },
                  uniqueId: () => `${domainId.value}:${currentReportId.value}`,
                  waitFor: () => domainId.value && currentReportId.value,
              })

    // We also pre-load the templates into swrv cache to make sure that
    // the templates don't jank up the UI when they pop in later in ReportTemplateSelector
    const { loading: templatesLoading } = useAPI<Reports.Core.ReportTemplate[]>(
        Endpoint.GetUserReportTemplates
    )
    const { loading: baseTemplatesLoading } = useAPI<Reports.Core.BaseReportTemplate>(
        Endpoint.GetBaseReportTemplate
    )

    const loading = computed(
        () =>
            !team.value ||
            !newReportInfo.value ||
            !preferences.value ||
            templatesLoading.value ||
            baseTemplatesLoading.value
    )

    /*
        Delicate dance of:
        - refreshing newReportInfo when _newReportInfo is first set from DB
        - refreshing conversion types every time _newReportInfo is updated from DB
        - saving & restoring form data when going through GA oauth flow 
    */

    const dehydratedReport = useLocalStorage<
        null | Reports.Core.NewReportInfo | Reports.Core.ReportWithUserDetails
    >(`savedReport-${accountId.value}`, null, { serializer: StorageSerializers.object })

    onMounted(() => {
        if (dehydratedReport.value) {
            // restore after coming back from GA oauth
            uneditedNewReportInfo.value = dehydratedReport.value
            dehydratedReport.value = null
        } else if (uneditedNewReportInfo.value) {
            // fix for weird quick-back navigation bug
            refreshFormData()
        }
    })

    watch(uneditedNewReportInfo, newVal => {
        if (!newVal) {
            // this gets triggered when the reports modal unloads
            // and uneditedNewReportInfo is set to undefined,
            // but we don't care to call refreshFormData()
            return
        }
        refreshFormData()
    })

    const refreshFormData = () => {
        const newVal = uneditedNewReportInfo.value

        // the first time it is loaded
        if (!newReportInfo.value && newVal) {
            newReportInfo.value = newVal

            // The first time newReportInfo is set, use whatever info it returned
            if (isExistingReport(newVal)) {
                title.value = newVal.title
                fromDate.value = parseISO(newVal.from_date)
                toDate.value = parseISO(newVal.to_date)
            }
        }

        if (!newReportInfo.value || !newVal) {
            throw new Error('newReportInfo or newVal is undefined, this should never happen')
        }

        if (isExistingReport(newVal) && isExistingReport(newReportInfo.value)) {
            newReportInfo.value.slides = newVal.slides
        }

        newReportInfo.value.conversion_types = newVal.conversion_types
        for (const conversionType of newVal.conversion_types) {
            // add it if and only if it doesn't already exist. We don't want to overwrite user choice.
            if (typeof selectedConversionTypes.value[conversionType.name] === 'undefined') {
                selectedConversionTypes.value[conversionType.name] = conversionType.selected
            }
        }
    }

    const saveToLocalStorage = () => {
        if (!newReportInfo.value) {
            throw new Error('Cannot save before newReportInfo exists')
        }
        dehydratedReport.value = {
            ...newReportInfo.value,
            title: title.value,
            from_date: format(fromDate.value, 'yyyy-MM-dd'),
            to_date: format(toDate.value, 'yyyy-MM-dd'),
        }
    }

    /*
        Handle updating newReportInfo fields. 
    */
    const updateSenderFields = (fields: Partial<Reports.Core.NewReportInfo['sender']>) => {
        if (!newReportInfo.value) {
            throw new Error('newReportInfo must be defined first')
        }
        newReportInfo.value.sender = {
            ...newReportInfo.value?.sender,
            ...fields,
        }
    }

    const updateRecipientFields = (fields: Partial<Reports.Core.NewReportInfo['recipient']>) => {
        if (!newReportInfo.value) {
            throw new Error('newReportInfo must be defined first')
        }
        newReportInfo.value.recipient = {
            ...newReportInfo.value?.recipient,
            ...fields,
        }
    }

    /*
        Handle report fields that live outside of newReportInfo
    */
    const selectedTemplateId = ref<number>()
    const templateId = computed(() => selectedTemplateId.value ?? preferences.value?.template_id)

    const setNewDateRange = async (dates: { start: Date; end: Date }) => {
        if (!newReportInfo.value) {
            throw new Error('newReportInfo must be set before updating date range')
        }

        fromDate.value = dates.start
        toDate.value = dates.end

        await refreshNewReportInfo()
    }

    /*
        Conversion table
    */
    const selectedConversionTypes = ref<Record<string, boolean>>({})
    const selectedConversionTypeCount = computed(() => {
        return (newReportInfo.value?.conversion_types ?? []).filter(
            c => !!selectedConversionTypes.value[c.name]
        ).length
    })

    const allConversionsSelected = computed(() => {
        return (newReportInfo.value?.conversion_types ?? [])
            .map(c => !!selectedConversionTypes.value[c.name])
            .every(c => c)
    })

    const isUsingCpa = computed(
        () =>
            !newReportInfo.value?.performance_mode ||
            newReportInfo.value?.performance_mode === Targets.PerformanceMode.CPA
    )

    const conversionTableHeaders = computed(() => {
        if (isUsingCpa.value) {
            return [
                { key: 'name', text: `_`, width: 420 }, // will be overwritten in oTable template
                { key: 'all_conversions', text: `Conv.`, width: 120 },
            ]
        }

        return [
            { key: 'name', text: `_`, width: 420 }, // will be overwritten in oTable template
            { key: 'all_conversions_value', text: `Value`, width: 120 },
        ]
    })

    const conversionTableItems = computed(() => {
        return (newReportInfo.value?.conversion_types ?? []).map(row => {
            return {
                ...row,
                all_conversions: round(row.all_conversions, 2),
                selected: selectedConversionTypes.value[row.name],
            }
        })
    })

    const selectConversionType = (name: string, selected: boolean) => {
        if (typeof selectedConversionTypes.value[name] === 'undefined') {
            throw new Error('this should never happen, is selectedConversionTypes inited properly?')
        }

        selectedConversionTypes.value[name] = selected
    }

    /*
        Schedules
    */
    const scheduleAlreadyCreated = computed(
        // find a schedule with id === undefined, that's the one we just created
        () => !!newReportInfo.value?.schedules.find(schedule => schedule.schedule_id === undefined)
    )

    const addSchedule = ({ interval, dayOfMonth }: { interval: string; dayOfMonth: number }) => {
        if (!newReportInfo.value) {
            throw new Error('Cannot create schedule before data is ready')
        }

        // 3rd of next month
        const nextRunTs = add(startOfMonth(add(new Date(), { months: 1 })), {
            days: dayOfMonth - 1,
        })

        const newSchedule = {
            title: title.value,
            interval_amount: +interval as Reports.Core.ReportScheduleInterval,
            interval_unit: 'months' as Reports.Core.ReportSchedule['interval_unit'],
            day: dayOfMonth as Reports.Core.ReportScheduleDay,
            active: true,
            schedule_id: undefined, // Schedule is created when report is created
            next_run_ts: format(nextRunTs, 'yyyy-MM-dd'),
        }

        newReportInfo.value.schedules = [newSchedule, ...newReportInfo.value?.schedules]
    }

    const removeSchedule = async (scheduleId: number | undefined) => {
        if (!newReportInfo.value) {
            throw new Error('Cannot delete schedule before data is ready')
        }

        newReportInfo.value.schedules = newReportInfo.value.schedules.filter(
            schedule => schedule.schedule_id !== scheduleId
        )
        if (scheduleId) {
            // delete schedule from the database
            await authRequest<void>(Endpoint.CancelSchedule, {
                body: {
                    schedule_id: scheduleId,
                },
            })
        }
    }

    /*
        Validation & CreateReport
    */
    const creationFormReady = computed(() => {
        if (!newReportInfo.value) {
            return false
        }
        return [
            domainInfo.value,
            newReportInfo.value,
            templateId.value,
            !validateTitle(title.value),
            !validateSpendBottom(newReportInfo.value.spend_target.bottom),
            !validateSpendTop(newReportInfo.value.spend_target.top),
            !validateCpaBottom(newReportInfo.value.cpa_target.bottom),
            !validateCpaTop(newReportInfo.value.cpa_target.top),
            !validateRoasBottom(newReportInfo.value.roas_target.bottom),
            !validateRoasTop(newReportInfo.value.roas_target.top),
        ].every(t => t)
    })

    const validateTitle = (value: string) => {
        if (value.length > MAX_TITLE_CHARS) {
            return 'Report Title cannot be over 25 characters'
        } else if (value.length === 0) {
            return 'Reports must have a title'
        } else if (value.includes('/')) {
            return 'Report title cannot contain slashes'
        }
    }

    const validateSpendBottom = (val: number) => {
        if (val > (newReportInfo.value?.spend_target.top ?? 0)) {
            return 'Cannot be greater than to value'
        }
    }
    const validateSpendTop = (val: number) => {
        if (val < (newReportInfo.value?.spend_target.bottom ?? 0)) {
            return 'Cannot be smaller than from value'
        }
    }
    const validateCpaBottom = (val: number) => {
        if (val > (newReportInfo.value?.cpa_target.top ?? 0)) {
            return 'Cannot be greater than to value'
        }
    }
    const validateCpaTop = (val: number) => {
        if (val < (newReportInfo.value?.cpa_target.bottom ?? 0)) {
            return 'Cannot be smaller than from value'
        }
    }
    const validateRoasBottom = (val: number) => {
        if (val > (newReportInfo.value?.roas_target.top ?? 0)) {
            return 'Cannot be greater than to value'
        }
    }
    const validateRoasTop = (val: number) => {
        if (val < (newReportInfo.value?.roas_target.bottom ?? 0)) {
            return 'Cannot be smaller than from value'
        }
    }

    const creatingReport = ref(false)
    const creatingReportError = ref(false)
    const createReport = async () => {
        creatingReport.value = true
        creatingReportError.value = false
        try {
            if (!domainInfo.value || !newReportInfo.value) {
                throw new Error('cannot create report before data is ready')
            }

            if (!templateId.value) {
                throw new Error('templateId must be selected to create a report')
            }

            if (!title.value) {
                throw new Error('title must be set to create a report')
            }

            const conversionTypes = newReportInfo.value.conversion_types.map(c => {
                return {
                    ...c,
                    selected: selectedConversionTypes.value[c.name],
                }
            })

            const options: Reports.Core.CreateReportOptions = {
                ...newReportInfo.value,
                title: title.value,
                from_date: format(fromDate.value, 'yyyy-MM-dd'),
                to_date: format(toDate.value, 'yyyy-MM-dd'),
                template_id: templateId.value,
                currency: currencySymbol.value,
                conversion_types: conversionTypes,
            }

            if (mode === 'create') {
                if ((options as any).report_id) {
                    throw new Error('cannot create a report that already exists')
                }

                const localDomainId = domainId.value

                const [report] = await Promise.all([
                    authRequest<Reports.Core.Report>(Endpoint.CreateReport, {
                        body: {
                            domain_id: domainId.value,
                            options,
                        },
                    }),
                    delay(MIN_REPORT_CREATE_TIME),
                ])

                await refreshReportsList()

                if (
                    localDomainId !== domainId.value ||
                    currentRoute.value.name !== Routes.ReportCreate
                ) {
                    // don't do anything if the user navigated away from the page
                    return
                }

                push({ name: Routes.ReportSlides, params: { reportId: report.report_id } })

                intercom.trackEvent('report_created')
            }

            if (mode === 'update') {
                await Promise.all([
                    authRequest<void>(Endpoint.UpdateReport, {
                        body: {
                            domain_id: domainId.value,
                            report_id: currentReportId.value,
                            options,
                        },
                    }),
                    delay(MIN_REPORT_CREATE_TIME),
                ])
                await refreshNewReportInfo()
                await refreshReportsList()
            }
        } catch (e) {
            creatingReportError.value = true
            throw e // throw so it is caught by datadog
        }
    }

    /*
        Provide & return data
    */
    const toProvide: CreateReportExports = {
        loading,
        title,
        fromDate,
        toDate,
        newReportInfo,
        setNewDateRange,
        updateSenderFields,
        updateRecipientFields,
        team,
        templateId,
        selectedTemplateId,
        createReport,
        creationFormReady,
        validateTitle,
        creatingReport,
        creatingReportError,
        conversionTableHeaders,
        conversionTableItems,
        allConversionsSelected,
        selectedConversionTypeCount,
        selectConversionType,
        validateSpendBottom,
        validateSpendTop,
        validateCpaBottom,
        validateCpaTop,
        validateRoasBottom,
        validateRoasTop,
        saveToLocalStorage,
        addSchedule,
        removeSchedule,
        scheduleAlreadyCreated,
        isUsingCpa,
    }

    provide<CreateReportExports>(`createReport:${mode}`, toProvide)

    return toProvide
}

export function useCreateReport() {
    const { params } = useRoute()

    const injected = params.reportId
        ? inject<CreateReportExports>('createReport:update')
        : inject<CreateReportExports>('createReport:create')

    if (!injected) {
        throw new Error(
            `Report not yet injected, something is wrong. useCreateReport() can only be called in a reports/active/create or reports/active/:id/update route.`
        )
    }
    return injected
}
