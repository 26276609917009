import { provide, Ref, ref, computed, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import formatDate from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { Reports } from '@opteo/types'
import { useDomain } from '@/composition/domain/useDomain'
import { Routes } from '@/router/routes'
import { useReportsList } from './useReportsList'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { scrollToTop } from '@/lib/globalUtils'

export interface UseReportExports {
    report: Ref<Reports.Core.Report | undefined>
    loading: Ref<boolean>
    refreshReport: (report?: () => Reports.Core.Report) => Promise<void>
    previewMode: ComputedRef<boolean>
    setPreviewMode: (status: boolean) => void
    activeSlides: ComputedRef<Reports.Core.ReportSlide[]>
    reportId: ComputedRef<number>
    isArchive: ComputedRef<boolean>
    closeReport: () => void
    downloadReport: () => Promise<string>
    primaryColour: ComputedRef<string>
    reportPeriodFrom: ComputedRef<string>
    reportPeriodTo: ComputedRef<string>
}

export function provideReport() {
    /* 
        Setup 
    */
    const { domainId } = useDomain()
    const { currentRoute, push } = useRouter()
    const { mutate: refreshReportsList } = useReportsList()
    const intercom = useIntercom()
    const reportId = computed(() => +currentRoute.value.params.reportId)
    const isArchive = computed(() => currentRoute.value.path.includes('/archive'))

    const {
        data: report,
        loading,
        mutate: refreshReport,
    } = useAPI<Reports.Core.Report>(Endpoint.GetReport, {
        body: () => {
            return {
                report_id: reportId.value,
                from_archive: isArchive.value,
            }
        },
        uniqueId: () => `${domainId.value}:${reportId.value}`,
        waitFor: () => domainId.value && reportId.value,
    })

    /* 
        Preview Mode toggle 
    */
    const previewModeSetting = ref(false)
    const previewMode = computed(() => previewModeSetting.value || isArchive.value)

    const setPreviewMode = (previewMode: boolean) => {
        previewModeSetting.value = previewMode
        if (previewMode) {
            // Scroll to top when flipping to preview mode
            scrollToTop('smooth')
        }
    }

    /* 
        Convenience computed vars 
    */
    const primaryColour = computed(() => {
        return report.value?.theme.primary_colour ?? '#fffff'
    })

    const activeSlides = computed(() => {
        if (previewMode.value) {
            return report.value?.slides.filter(slide => slide.active && !slide.invalid) ?? []
        } else {
            return report.value?.slides ?? []
        }
    })

    const reportPeriodFrom = computed(() =>
        report.value ? formatDate(parseISO(report.value.from_date), 'MMMM do yyyy') : ''
    )

    const reportPeriodTo = computed(() =>
        report.value ? formatDate(parseISO(report.value.to_date), 'MMMM do yyyy') : ''
    )

    /* 
        Methods 
    */
    const downloadReport = async () => {
        const { download_url } = await authRequest<{ download_url: string }>(
            Endpoint.DownloadReport,
            {
                body: {
                    report_id: reportId.value,
                    domain_id: domainId.value,
                    use_render_url: true,
                },
            }
        )

        refreshReportsList() // refresh reports list to get latest last_exported_ts (updates status icon)

        intercom.trackEvent('report_exported')

        return download_url
    }

    const closeReport = () =>
        push({ name: !isArchive.value ? Routes.ReportsActive : Routes.ReportsArchive })

    /*
        Provide & return data
    */

    const toProvide: UseReportExports = {
        reportId,
        report,
        loading,
        refreshReport,
        previewMode,
        setPreviewMode,
        activeSlides,
        isArchive,
        closeReport,
        downloadReport,
        primaryColour,
        reportPeriodFrom,
        reportPeriodTo,
    }

    provide<UseReportExports>('report:' + reportId.value, toProvide)

    return toProvide
}
