<template>
    <div v-if="loading">
        <ReportSlidesSkeleton />
    </div>
    <ReportSlides />
    <ReportFooter :title="report?.title" :subTitle="reportPeriodFrom + ' → ' + reportPeriodTo">
        <!-- Edit mode, Active -->
        <oButton
            v-if="!previewMode && !isArchive"
            @clicked="returnToUpdate"
            :disabled="loading"
            id="cancel-footer-btn"
            color="white"
            class="mr2 w-100 w-auto"
        >
            Update Report
        </oButton>
        <oButton
            v-if="!previewMode && !isArchive"
            @clicked="setPreviewMode(true)"
            :disabled="loading"
            color="domain-color"
            class="w-auto"
        >
            Preview Report
            <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
        </oButton>

        <!-- Preview mode, Active -->
        <oButton
            v-else-if="previewMode && !isArchive"
            @clicked="setPreviewMode(false)"
            :disabled="loading"
            color="white"
            class="mr2"
        >
            Edit Report
        </oButton>
        <oButton
            v-if="previewMode && !isArchive"
            @clicked="finishReport"
            :disabled="loading"
            color="green"
            class="w-auto"
        >
            Complete Report
            <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
        </oButton>

        <!-- Preview mode, Archive -->
        <oButton v-else-if="isArchive" @clicked="returnToReports" color="white" class="mr2">
            Back to Archive
        </oButton>
        <oButton
            v-if="isArchive"
            @clicked="finishReport"
            :disabled="loading"
            color="green"
            class="w-auto"
        >
            Complete Report
            <template v-slot:icon><RightArrowIcon hex="#fff" /></template>
        </oButton>
    </ReportFooter>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { oButton, CloseButton, showSnackbar, RightArrowIcon } from '@opteo/components-next'
import ReportSlides from '@/components/Reports/ReportSlides.vue'
import ReportFooter from '@/components/Reports/ReportFooter.vue'
import ReportSlidesSkeleton from '@/components/Reports/ReportSlidesSkeleton.vue'

import { useReport } from '@/composition/reports/useReport'
import { Routes } from '@/router/routes'

export default defineComponent({
    components: {
        oButton,
        CloseButton,
        ReportSlides,
        ReportFooter,
        ReportSlidesSkeleton,
        RightArrowIcon,
    },
    name: 'ReportsActive',
    setup() {
        const {
            report,
            loading,
            previewMode,
            setPreviewMode,
            activeSlides,
            isArchive,
            reportPeriodFrom,
            reportPeriodTo,
        } = useReport()

        const { push } = useRouter()

        const returnToUpdate = () => {
            push({ name: Routes.ReportUpdate })
        }

        const returnToReports = () => {
            push({ name: isArchive.value ? Routes.ReportsArchive : Routes.ReportsActive })
        }

        const finishReport = () => {
            setPreviewMode(false)

            if (activeSlides.value.length > 0) {
                push({
                    name: isArchive.value ? Routes.ReportDownloadArchive : Routes.ReportDownload,
                })
            } else {
                showSnackbar({
                    message: `You must have at least one slide enabled!`,
                    timeout: 5000,
                })
            }
        }

        return {
            report,
            loading,
            returnToUpdate,
            setPreviewMode,
            finishReport,
            isArchive,
            previewMode,
            returnToReports,
            reportPeriodFrom,
            reportPeriodTo,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
</style>
